import React from 'react';
import { Layout } from 'components';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';

import BlogThumbnail from 'components/Blog/Thumbnail';

export default function CategoryPosts({ data: { site, blog, allPosts, category } }) {
  return (
    <Layout>
      <div className="container mx-auto">
        <HelmetDatoCms seo={blog.seo} favicon={site.favicon} />
        <h2 className="mb-2 mt-1 ml-1">Category : {category.name}</h2>
        {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}> */}
        <div className="srow">
          {allPosts.nodes.map((post, index) => (
            <BlogThumbnail key={index} post={post} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query PostsByCatergorySlug($slug: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    category: datoCmsCategory(slug: { eq: $slug }) {
      name
    }
    allPosts: allDatoCmsPost(
      filter: { category2: { elemMatch: { slug: { eq: $slug } } } }
      limit: 20
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 760, height: 428)
        }
        category: category2 {
          # 'category' name gives an error
          name
          originalId
          slug
        }
        author {
          name
          picture {
            gatsbyImageData(layout: FIXED, width: 48, height: 48, imgixParams: { sat: -100 })
          }
        }
      }
    }
  }
`;
